import { useAuthStore } from '~/store/auth';

export default defineNuxtRouteMiddleware(async () => {
    const authStore = useAuthStore();
    if (!authStore.userData) {
        await authStore.fetchUser();
    }
    if (
        authStore.userData &&
        !authStore.userData.onboardQuestionnaireCompleted
    ) {
        return navigateTo('/onboarding');
    }
});
